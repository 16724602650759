<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    Landing Page
  </div>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
